import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

// import StartPage from './pages/Start/StartPage';
import MenuPage from './pages/Menu/MenuPage';
import CartPage from './pages/Cart/CartPage';
import AppContext from './shared/AppContext';

const App = () => {
	return (
		<AppContext.Provider
			value={{
				cart: {
					items: [],
				},
				store: null,
				sliderAnimation: true,
				menuList: [],
				categories: [],
				storeName: '',
				storeId: '',
				priceListId: '',
			}}>
			<Router>
				{/* <Route path='/' exact component={StartPage} /> */}
				<Route path='/' exact component={MenuPage} />
				<Route path='/cart' exact component={CartPage} />
			</Router>
		</AppContext.Provider>
	);
};

export default App;

import React from 'react';
import styles from './MenuListItem.module.scss';
import { IMenuItem } from '../../shared/models/MenuItem';
import addImage from '../../assets/add.svg';
import removeImage from '../../assets/remove.svg';
import listImage from '../../assets/list-white.svg';
import close from '../../assets/close.svg';
import { useTranslation } from 'react-i18next';
import AppContext, { IAppContext } from '../../shared/AppContext';

interface MenuListItemProps {
	item: IMenuItem;
	inCart: number;
	onAdd: () => void;
	onRemove: () => void;
}

const MenuListItem = (props: MenuListItemProps) => {
	const { t } = useTranslation();
	const context: IAppContext = React.useContext(AppContext);

	const [inCart, setInCart] = React.useState<number>(0);
	const [showModal, setShowModal] = React.useState<boolean>(false);

	const imgStyle = {
		backgroundImage: 'url(' + props.item.mainImage?.url + ')',
	};

	React.useEffect(() => {
		setInCart(props.inCart);
	}, [props.inCart]);

	const add = () => {
		setInCart(inCart + 1);
		props.onAdd();
	};

	const remove = () => {
		setInCart(inCart - 1);
		props.onRemove();
	};

	const toggleModal = () => {
		setShowModal(!showModal);
	};

	return (
		<React.Fragment>
			<div className={styles.menuItem}>
				<div
					className={styles.imageContainer}
					style={imgStyle}
					onClick={toggleModal}></div>
				<div className={styles.infos}>
					<span className={styles.name} onClick={toggleModal}>
						{props.item.name}
					</span>
					<span className={styles.description} onClick={toggleModal}>
						{props.item.description}
					</span>
					<div className={styles.price}>
						<span>
							{props.item.price.amount}
							{context.store?.currency}
						</span>
					</div>
					<div className={styles.qty}>
						{inCart > 0 ? (
							<img src={removeImage} className={`${context.storeName}`} alt='remove' onClick={remove} />
						) : null}
						{inCart > 0 ? (
							<span className={styles.qtyNumber}>{props.inCart}</span>
						) : null}
						<img src={addImage} className={`${context.storeName}`} alt='remove' onClick={add} />
					</div>
				</div>
			</div>
			{showModal ? (
				<div className={styles.modal}>
					<div className={styles.modalHeader}>
						<img
							src={close}
							alt='close'
							className={styles.closeIcon}
							onClick={toggleModal}
						/>
						<span className={styles.title}>{props.item.name}</span>
					</div>
					<div className={styles.bigImage} style={imgStyle}>
						<div className={styles.price}>
							<span>
								{props.item.price.amount}
								{context.store?.currency}
							</span>
						</div>
					</div>
					<div className={styles.paragraph}>
						<span className={styles.title}>
							{t('menu-page.item-modal.description', 'descrizione')}
						</span>
						<p className={styles.content}>{props.item.description}</p>
					</div>
					<div className={styles.paragraph}>
						<span className={styles.title}>
							{t('menu-page.item-modal.allergens', 'allergeni')}
						</span>
						<p className={styles.content}>
							{props.item.allergens.map((a, i) => {
								return (
									<span key={a.id}>
										{a.name}
										{i === props.item.allergens.length - 1 ? '.' : ', '}
									</span>
								);
							})}
						</p>
					</div>
					<div className={styles.bottom}>
						{inCart > 0 ? (
							<div className={[styles.qtyManager, context.storeName].join(' ')}>
								{inCart > 0 ? (
									<img src={removeImage} alt='remove' onClick={remove} />
								) : null}
								{inCart > 0 ? (
									<span className={styles.qtyNumber}>{props.inCart}</span>
								) : null}
								<img src={addImage} alt='remove' onClick={add} />
							</div>
						) : (
							<div className={[styles.addButton, context.storeName].join(' ')} onClick={add}>
								<span>
									{t('menu-page.item-modal.add-to-list', 'aggiungi alla lista')}
								</span>
								<img src={listImage} alt='list' />
							</div>
						)}
					</div>
				</div>
			) : null}
		</React.Fragment>
	);
};

export default MenuListItem;

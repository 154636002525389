import * as React from 'react';
import ICart from '../../shared/models/Cart';
import { Location } from "history";
import { ApiService } from '../../shared/services/ApiService';
import { IMenuItem } from '../../shared/models/MenuItem';
import MenuCategorySelector from '../../components/MenuCategorySelector/MenuCategorySelector';
import MenuListItem from '../../components/MenuListItem/MenuListItem';
import ICartItem from '../../shared/models/CartItem';
import Header from '../../components/Header/Header';
import AppContext, { IAppContext } from '../../shared/AppContext';
import { Link } from 'react-router-dom';
import { IMenu } from '../../shared/models/Menu';
import { useTranslation } from 'react-i18next';
import LayoutContainer from '../../components/LayoutContainer/LayoutContainer';
import styles from './MenuPage.module.scss';
import listIcon from '../../assets/list.svg';
import Background from '../../components/Background/Background';
import Loader from '../../components/Loader/Loader';

interface IQueryString {
	storeName?: string,
	storeId?: string,
	priceListId?: string,
}

const MenuPage = (props: { location: Location }) => {
	const { t, i18n } = useTranslation();
	const apiService = new ApiService();
	const applicationLanguage = i18n.language;

	// STATE
	const [cart, setCart] = React.useState<ICart>({
		items: [],
	});
	const [selectedCategory, setSelectedCategory] = React.useState<string>('');
	// const [restaurantCode, setRestaurantCode] = React.useState<string>('');
	const [menuCategories, setMenuCategories] = React.useState<string[]>([]);
	const [menuItems, setMenuItems] = React.useState<IMenuItem[]>([]);
	const [loaded, setLoaded] = React.useState<boolean>(false);
	const [isError, setIsError] = React.useState<boolean>(false);
	const [itemsInCart, setItemsInCart] = React.useState<number>(0);
	const [languageValue, setLanguageValue] = React.useState<string>(applicationLanguage);

	// CONTEXT
	const context: IAppContext = React.useContext(AppContext);

	React.useEffect(() => {
		async function getMenu() {
			console.log('Requesting menu', applicationLanguage)
			try {
				let parsedSearch: IQueryString = {};

				if (context.storeId) {
					parsedSearch = {
						storeId: context.storeId,
						storeName: context.storeName,
						priceListId: context.priceListId,
					}
				} else {
					const search = props.location.search.substring(1);
					parsedSearch = search.split('&').reduce((prev, s) => {
						const values = s.split('=');
						return { ...prev, [values[0]]: values[1] }
					}, {});
				}

				if (!parsedSearch.storeName || !parsedSearch.storeId || !parsedSearch.priceListId) {
					throw new Error('Missing query string parameters');
				}

				context.storeName = parsedSearch.storeName;
				context.storeId = parsedSearch.storeId;
				context.priceListId = parsedSearch.priceListId;

				setLoaded(false);

				const totalMenu: IMenu = await apiService.getMenu({
					storeId: parsedSearch.storeId,
					storeName: parsedSearch.storeName,
					priceListId: parsedSearch.priceListId,
					language: applicationLanguage,
				});
				// define categories
				let categories: string[] = [];
				totalMenu.data.forEach((i: IMenuItem) => {
					i.taxonomies.category.forEach((c) => {
						if (categories.indexOf(c.name) === -1) {
							categories.push(c.name);
						}
					});
				});
				setMenuCategories(categories);
				setMenuItems(totalMenu.data);
				context.store = totalMenu.store;
				// save menu and categories in context to avoid getting it everytime menu page loads
				context.menuList = totalMenu.data;
				context.categories = categories;
				
				setLoaded(true);
			} catch (error) {
				console.log(error)
				setLoaded(true);
				setIsError(true);
			}
		}

		if (context.categories.length === 0 || context.menuList.length === 0) {
			getMenu();
		} else {
			setLoaded(true);
			setMenuCategories(context.categories);
			setMenuItems(context.menuList);
		}
		setCart(context.cart);
		countItemsInCart();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [applicationLanguage]);

	const categoryChange = (category: string) => {
		setSelectedCategory(category);
	};

	const changeItemQuantity = (item: IMenuItem, quantityToAdd: number) => {
		const index = cart.items.findIndex((i: ICartItem) => {
			return i.id === item.id;
		});
		let newItems = cart.items;
		if (index > -1) {
			newItems[index].qty += quantityToAdd;
			if (newItems[index].qty === 0) {
				newItems.splice(index, 1);
			}
		} else {
			newItems.push({
				...item,
				qty: 1,
			});
		}
		countItemsInCart();
		setCart({
			items: newItems,
		});
		context.cart = cart;
	};

	const getInCartQty = (id: string): number => {
		const item = cart.items.find((i: ICartItem) => {
			return i.id === id;
		});
		return item?.qty || 0;
	};

	const countItemsInCart = () => {
		setItemsInCart(0);
		let count = 0;
		context.cart.items.forEach((i) => {
			count += i.qty;
		});
		setItemsInCart(count);
	};


	const handleChangeLanguage = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setLanguageValue(e.target.value);
		context.categories = [];
		context.menuList = [];
		i18n.changeLanguage(e.target.value);
	}

	return (
		<React.Fragment>
			{!loaded ? (
				<Loader
					message={t(
						'menu-page.menu-loading',
						'Stiamo caricando il menu'
					)}></Loader>
			) : null}
			{loaded && isError &&
				<Loader
					message={t(
						'menu-page.error',
					)}></Loader>
			}
			<Header
				left={
					<select className={styles.languageSelect} value={languageValue} onChange={handleChangeLanguage}>
						{['fr', 'en', 'es', 'it'].map(l => <option key={l} value={l}>{l}</option>)}
					</select>
				}
				right={
					<Link to={{ pathname: '/cart', search: props.location.search }} className={styles.cartLink}>
						{itemsInCart > 0 ? (
							<div className={styles.cartBadge}>
								<span>{itemsInCart}</span>
							</div>
						) : null}
						<img src={listIcon} alt='cart' />
					</Link>
				}></Header>
			{loaded ? (
				<MenuCategorySelector
					categories={menuCategories}
					onCategoryChange={(c: string) =>
						categoryChange(c)
					}></MenuCategorySelector>
			) : null}
			<Background>
				<LayoutContainer>
					{loaded
						? menuItems.map((i: IMenuItem) => {
							let show = true;
							if (selectedCategory !== '') {
								const c = i.taxonomies.category.find((c) => {
									return c.name === selectedCategory;
								});
								if (!c) {
									show = false;
								}
							}
							return show ? (
								<MenuListItem
									key={i.id}
									item={i}
									inCart={getInCartQty(i.id)}
									onAdd={() => {
										changeItemQuantity(i, 1);
									}}
									onRemove={() => {
										changeItemQuantity(i, -1);
									}}></MenuListItem>
							) : null;
						})
						: null}
				</LayoutContainer>
			</Background>
		</React.Fragment>
	);
};

export default MenuPage;

import axios from 'axios';
// import menuMock from '../mocks/menu.json';
import { IMenu } from '../models/Menu';

interface Environment {
	baseUrl?: string;
	authenticationHeader?: string;
}

export class ApiService {
	environment: Environment;
	constructor() {
		this.environment = {
			baseUrl: process.env.REACT_APP_API_BASE_URL,
			authenticationHeader:
				process.env.REACT_APP_MIDDLEWARE_AUTHENTICATION_HEADER,
		};

		axios.interceptors.request.use((config) => {
			config.headers = {
				Authentication: this.environment.authenticationHeader,
				'Content-type': 'application/json',
			};
			return config;
		});
	}

	async getMenu({ storeName, storeId, priceListId, language }: { storeName: string, storeId: string, priceListId: string, language: string }): Promise<IMenu> {
		// TODO: complete with real api call
		// return new Promise((resolve, reject) => {
		// 	resolve(menuMock);
		// });
		const data = (await axios.get(
			this.environment.baseUrl+`/api/v1/menu/list/${storeName}/feb/menu/${storeId}/${priceListId}?language=${language}`,
			{
				headers: {
					'Authorization': this.environment.authenticationHeader,
				},
			})).data.data.products;

		return { data } as IMenu;
	}
}

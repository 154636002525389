import React from 'react';
import styles from './MenuCategorySelector.module.scss';
import classnames from 'classnames/bind';
import AppContext, { IAppContext } from '../../shared/AppContext';

interface MenuCategorySelectorProps {
	categories: string[];
	onCategoryChange: (c: string) => void;
}

const MenuCategorySelector = (props: MenuCategorySelectorProps) => {
	const [selectedCategory, setSelectedCategory] = React.useState<string>('');

	const context: IAppContext = React.useContext(AppContext);
	const sx = classnames.bind(styles);
	const sliderClasses = sx({
		slider: true,
		animated: context.sliderAnimation,
	});
	setTimeout(() => {
		context.sliderAnimation = false;
	}, 5000);

	const categoryChange = (c: string) => {
		if (c === selectedCategory) {
			c = '';
		}
		props.onCategoryChange(c);
		setSelectedCategory(c);
	};

	return (
		<React.Fragment>
			<div className={styles.categoriesContainer}>
				<div className={sliderClasses}>
					{props.categories.map((c: string, i: number) => {
						let cx = classnames.bind(styles);
						var catClasses = cx({
							category: true,
							selected: c === selectedCategory,
						});
						return (
							<div
								key={c}
								className={catClasses}
								onClick={() => categoryChange(c)}>
								<span>{c}</span>
							</div>
						);
					})}
				</div>
			</div>
		</React.Fragment>
	);
};

export default MenuCategorySelector;

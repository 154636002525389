import * as React from 'react';
import styles from './Header.module.scss';
import logo from '../../assets/logo.svg';
import AppContext, { IAppContext } from '../../shared/AppContext';

const Header = (props: any) => {
	const context: IAppContext = React.useContext(AppContext);
	let logoToDisplay = logo;
	switch (context.storeName) {
		case 'temakinho': logoToDisplay = logo; break;
		default: logoToDisplay = logo;
	}

	return (
		<header className={styles.header}>
			<div className={styles.sideLeft}>{props.left}</div>
			{props.center ? (
				<div className={styles.center}>{props.center}</div>
			) : null}
			{!props.center ? (
				<img src={logoToDisplay} alt='logo' className={styles.logo} />
			) : null}
			<div className={styles.sideRight}>{props.right}</div>
		</header>
	);
};

export default Header;

import * as React from 'react';
import styles from './Loader.module.scss';

interface LoaderProps {
	message: string;
}

const Loader = (props: LoaderProps) => {
	return (
		<div className={styles.loader}>
			<span>{props.message}</span>
		</div>
	);
};

export default Loader;

import * as React from 'react';
import styles from './Background.module.scss';

const Background = (props: any) => {
	return props.white ? (
		<div className={styles.backgroundWhite}>{props.children}</div>
	) : (
		<div className={styles.background}>{props.children}</div>
	);
};

export default Background;

import * as React from 'react';
import ICartItem from './models/CartItem';
import { IStore } from './models/Store';
import { IMenuItem } from './models/MenuItem';

export interface IAppContext {
	cart: {
		items: ICartItem[];
	};
	store: IStore | null;
	sliderAnimation: boolean;
	menuList: IMenuItem[];
	categories: string[];
	storeName: string;
	storeId: string;
	priceListId: string;
}

const AppContext = React.createContext<IAppContext>({
	cart: {
		items: [],
	},
	store: null,
	sliderAnimation: true,
	menuList: [],
	categories: [],
	storeName: '',
	storeId: '',
	priceListId: '',
});

export default AppContext;

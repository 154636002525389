import * as React from 'react';

import AppContext, { IAppContext } from '../../shared/AppContext';
import ICartItem from '../../shared/models/CartItem';
import MenuListItem from '../../components/MenuListItem/MenuListItem';
import ICart from '../../shared/models/Cart';
import { IMenuItem } from '../../shared/models/MenuItem';
import Header from '../../components/Header/Header';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './CartPage.module.scss';
import backIcon from '../../assets/back.svg';
import Background from '../../components/Background/Background';
import LayoutContainer from '../../components/LayoutContainer/LayoutContainer';

const CartPage = (props: { location: Location }) => {
	const { t } = useTranslation();
	// STATE
	const [cart, setCart] = React.useState<ICart>({
		items: [],
	});

	// CONTEXT
	const context: IAppContext = React.useContext(AppContext);

	// DID MOUNT
	React.useEffect(() => {
		setCart(context.cart);
	}, [cart, context.cart]);

	const changeItemQuantity = (item: IMenuItem, quantityToAdd: number) => {
		const index = cart.items.findIndex((i: ICartItem) => {
			return i.id === item.id;
		});
		let newItems = cart.items;
		if (index > -1) {
			newItems[index].qty += quantityToAdd;
			if (newItems[index].qty === 0) {
				newItems.splice(index, 1);
			}
		} else {
			newItems.push({
				...item,
				qty: 1,
			});
		}
		setCart({
			items: newItems,
		});
		context.cart = cart;
	};

	const getInCartQty = (id: string): number => {
		const item = cart.items.find((i: ICartItem) => {
			return i.id === id;
		});
		return item?.qty || 0;
	};

	const resetList = () => {
		context.cart = {
			items: [],
		};
		setCart(context.cart);
	};

	return (
		<React.Fragment>
			<Header
				left={
					<Link to={{ pathname: '/', search: props.location.search }} className={styles.backLink}>
						<img src={backIcon} alt='back' />
					</Link>
				}
				center={
					<span className={styles.title}>
						{t('cart-page.my-orders', 'i miei piatti')}
					</span>
				}
				right={
					<span className={styles.cancelLink} onClick={resetList}>
						{t('cart-page.reset-list', 'azzera')}
					</span>
				}></Header>
			<Background>
				<LayoutContainer>
					{context.cart.items.map((i: ICartItem) => {
						return (
							<MenuListItem
								key={i.id}
								item={i}
								inCart={getInCartQty(i.id)}
								onAdd={() => {
									changeItemQuantity(i, 1);
								}}
								onRemove={() => {
									changeItemQuantity(i, -1);
								}}></MenuListItem>
						);
					})}
				</LayoutContainer>
			</Background>
		</React.Fragment>
	);
};

export default CartPage;
